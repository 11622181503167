import React from 'react';
import '../style/call_to_action.scss';
import '../style/color.scss';

class CallToAction extends React.Component {

  render() {
    return (
      <div id='call-to-action-block-container'>
        <div id='icon-container'>
          <div id='icon' />
        </div>
        <div id='call-to-action-block'>
          <p>
            {this.props.body}
          </p>
          <a href="mailto:info@tobyrichey.com">
            Contact Us
          </a>
        </div>
      </div>
    )
  }
}

export default CallToAction;
