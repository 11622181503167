import React, { Component } from 'react';
import ChapterHero from '../components/chapter_hero.js';
import Layout from '../components/layout.js';
import DecoBorder from '../components/deco_border.js';
import CallToAction from '../components/call_to_action.js';
import { Helmet } from "react-helmet";
import '../style/practice_acquisition.scss';
import '../style/chapters.scss';
import 'font-awesome/css/font-awesome.min.css';
import Anime from 'animejs/lib/anime.es.js';


class PracticeAcquisition extends Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  state = {
    isVisible: false,
    iconIsVisible: false
  }

  handleIconAnimation() {
    Anime({
      targets: '#left',
      translateX: 44,
      duration: 4000
    });

    Anime({
      targets: '#right',
      translateX: -44,
      duration: 4000
    });
  }

  handleFadeIn() {
    Anime({
      targets: '#box-01, #box-02, #box-03',
      opacity: 1,
      translateY: -20,
      duration: 1600,
      delay: Anime.stagger(800),
      easing: 'easeInOutQuart'
    });
  }

  //trigger animations if elements are in view

  fadeInOnPageLoad() {
   // If the initial window is high enough to show elements
    let element = document.getElementById('bullets-container');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.95 * window.innerHeight))) && (this.state.isVisible === false)) {
        // fade them in
        this.setState({isVisible: true});
        this.handleFadeIn();
      }
    }
  }

  // trigger animations if user scrolls to elements

  handleScroll() {
    const element = document.getElementById('bullets-container');
    // const icon = document.getElementById('tr-icon');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.7 * window.innerHeight))) && (this.state.isVisible === false)) {
        this.setState({isVisible: true});
        this.handleFadeIn();
      }
    }

  }

  componentDidMount() {
    this.fadeInOnPageLoad();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.setState({isVisible: false});
  }

  render() {
    return (
      <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Careers</title>
        <link rel="canonical" href="https://www.tobyrichey.com/destinations" />
      </Helmet>
        <ChapterHero
          headline='Growth Through Acquisition.'
          subheader='Explore new opportunities to accelerate your potential.'
          classList='chapter-hero-container'
          img={this.props.data.handshake.childImageSharp.fluid}/>
        <DecoBorder />
        <div id='practice-acquisition-container'>
          <div id='practice-acquisition' className='chapter-content'>
            <div className='body-container'>
              <div className='text-block'>
                <div id='text'>
                  <h3>
                    More financial advisors than ever are growing their businesses through strategic acquisitions.
                  </h3>
                  <p>
                    Acquiring an existing practice is an excellent strategy for rapid growth, complementary to the organic growth of your own practice. One of the biggest challenges inherent in this strategy is connecting with interested sellers. Many advisors receive virtually no assistance from their firm in finding or acquiring available practices. Toby Richey & Associates has extensive relationships with wealth management firms with robust resources for actively and aggressively acquiring practices for their advisors. We can expertly guide you through the process of engaging with these firms. The wealth management firms we represent take varying approaches in facilitating purchases up to and including:
                  </p>
                  <ul id='bullets-container' className='list-container'>
                    <li id='box-01'>Managers dedicated to constantly prospecting for interested sellers on behalf of advisors.</li>
                    <li id='box-02'>In-house financing with repayments made on a pre-tax basis.</li>
                    <li id='box-03'>Contributing substantially toward the total purchase price of a practice.</li>
                  </ul>
                </div>
                <div className='hor-rule' />
                <CallToAction
                  body='Contact Toby Richey & Associates today and let us help you develop a strategy to fast-track the growth of your practice.'
                  email='info@tobyRichey.com'
                />
              </div>
            </div>
          </div>
          <div className="svg-outer svg-outer-chapter-page">
            <svg className='svg-inner gray' width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
             <polygon points="0,0 0,100 100,100 100,0 50,98" />
            </svg>
          </div>
        </div>
      </Layout>
    )
  }
}


export const query = graphql`
  {
    handshake: file(relativePath: { eq:
    "handshake03.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`
  ;

export default PracticeAcquisition;
